<template>
  <v-treeview :items="items"></v-treeview>
</template>

<script>
import { mapGetters } from 'vuex'
import mitt from 'mitt'

export default {
  created: function () {
    this.testingExpanddatatable()
  },
  data() {
    return {
      cannedservices_testing: [],
       items: [
        {
          id: 1,
          name: 'Applications :',
          children: [
            { id: 2, name: 'Calendar : app' },
            { id: 3, name: 'Chrome : app' },
            { id: 4, name: 'Webstorm : app' },
          ],
        }
       ]
    }
  },

  methods: {
    testingExpanddatatable: function () {
      this.$store
        .dispatch('cannedService/searchTesting')
        .then(response => {
          this.cannedservices_testing = this.fetchTesting
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

  computed: {
    ...mapGetters({
      fetchTesting: 'cannedService/fetchCannedServices',
    }),
  },
}
</script>

<style>
</style>